#about-us{
    padding: 80px 4em 80px;
    background-color: #fff;
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 3em;

    & .card {
        display: flex;
        flex-direction: column;
        padding: 1em;
        border-radius: 10%;

        &:hover {
            background-color: #eee;
        }

        & p {
            margin-block: 1em 0;
            & strong {
                background-color: #8feef0;
                padding-inline: 3px;
            }
        }
    }
}