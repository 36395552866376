#copyright {
    color: #fff;
    position: relative;
    background-color: #7d2986;
    padding: 40px 1em 1em;
    font-size: calc(10px + 0.7vmin);

    & > p {
        text-align: right;
        margin: 0;

        padding: 0 3vmin 3vmin;
    }
}