@font-face {
  font-family: 'Helvetica Neue';
  src: url("/Assets/HelveticaNeueRegular.otf") format("opentype");
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue';
  letter-spacing: 0.1em;
  background-color: #000125;
  font-size: calc(10px + 1.2vmin);
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


/* custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #57588f;
  border-radius: 10px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9d9edb;
}
