#header {
    position: relative;
    background-color: #000125;
    color: #fff;
    padding: 100px 1em 100px 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & img {
        max-width: 80vw;
        max-height: 60vh;
    }

    & h1 {
        margin-block-start: 0.3em;
        margin-block-end: 0.2em;
        font-size: calc(18px + 3vmin);
        letter-spacing: 0.2em;
    }
    & h4 {
        margin-block-start: 0.2em;
        margin-block-end: 0.2em;
        font-size: calc(8px + 1.8vmin);
        letter-spacing: 0.2em;
    }
}